import ZoomVideo from "@zoom/videosdk";
import { KJUR } from "jsrsasign";
import storage_configs from "../config/storage-config";
import moment from "moment-timezone";

import conditional_configs from "../config/conditional_config";
import google_config from "../config/google_config";
import nlp from 'compromise';

//const zmClient = ZoomVideo.createClient();

export function storeZoomClient(zmClient) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.zoom.zmClient,
    JSON.stringify(zmClient)
  );
}

export function getZoomClient() {
  return localStorage.getItem(
    storage_configs.localStorage.sessions.zoom.zmClient
  );
}

export function checkIsZoomSignatureAlreadyExist() {
  return localStorage.getItem(
    storage_configs.localStorage.sessions.zoom.signature
  );
}

export function storeZoomSignature(signature) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.zoom.signature,
    signature
  );
}

export function checkIsZoomInitiated() {
  return localStorage.getItem(
    storage_configs.localStorage.sessions.zoom.isZoomIntiated
  );
}

export function zoomInitiated(slug) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.zoom.isZoomIntiated,
    true
  );
  localStorage.setItem(
    storage_configs.localStorage.sessions.zoom.currentSessionSlug,
    slug
  );
}

export function storeZoomCurrentUserData(data) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.zoom.user,
    JSON.stringify(data)
  );
}

export function storeZoomParticipants(data) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.zoom.participants,
    JSON.stringify(data)
  );
}

export function changeBreakoutSessionStatus(data) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.break_out_session.status,
    data
  );
}

export function getBreakoutSessionStatus() {
  return JSON.parse(
    localStorage.getItem(
      storage_configs.localStorage.sessions.break_out_session.status
    )
  );
}

export function stopBreakoutLoading(data) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.break_out_session.stopBreakoutLoading,
    data
  );
}

export function getWaitingParticipants() {
  let tempList = JSON.parse(
    localStorage.getItem(
      storage_configs.localStorage.sessions.zoom.waitingParticipants
    )
  );
  if (tempList && tempList.length > 0) {
    return tempList;
  } else {
    return [];
  }
}

//#region CHATS
export function storeChatNotifications(data) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.chatNotification,
    JSON.stringify(data)
  );
}

export function storeChatGeneralNotifications(data) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.chatGeneralNotification,
    JSON.stringify(data)
  );
}

export function findTypeOfMessage(txtMessage) {
  if (txtMessage) {
    let checkIsImg = txtMessage.includes("data:image/");
    return checkIsImg;
  }
};

export function getChats() {
  return JSON.parse(
    localStorage.getItem(storage_configs.localStorage.sessions.chats)
  );
}

export function storeChats(data) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.chats,
    JSON.stringify(data)
  );
}

export function removeAllChats() {
  localStorage.removeItem(storage_configs.localStorage.sessions.chats);
}

export function getOpenedChatGroup() {
  return JSON.parse(
    localStorage.getItem(storage_configs.localStorage.sessions.openedChatGroup)
  );
}

export function storeOpenedChatGroup(data) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.openedChatGroup,
    JSON.stringify(data)
  );
}

export function removeOpenedChatGroup() {
  localStorage.removeItem(
    storage_configs.localStorage.sessions.openedChatGroup
  );
}

export function getExpertOpenedCurrentGroupId() {
  return JSON.parse(
    localStorage.getItem(storage_configs.localStorage.sessions.current_group_id)
  );
}

export function getCompletedSessions() {
  return JSON.parse(
    localStorage.getItem(
      storage_configs.localStorage.sessions.completedSessions
    )
  );
}

export function storeCompletedSessions(data) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.completedSessions,
    JSON.stringify(data)
  );
}

export function removeCompletedSessions() {
  localStorage.removeItem(
    storage_configs.localStorage.sessions.completedSessions
  );
}

export function getCurriculumDetail() {
  return JSON.parse(
    localStorage.getItem(storage_configs.localStorage.sessions.curriculumDetail)
  );
}

export function storeCurriculumDetail(data) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.curriculumDetail,
    JSON.stringify(data)
  );
}

export function removeCurriculumDetail() {
  localStorage.removeItem(
    storage_configs.localStorage.sessions.curriculumDetail
  );
}

export function getWhiteboardTemplateList() {
  return JSON.parse(
    localStorage.getItem(
      storage_configs.localStorage.sessions.whiteboardTemplates
    )
  );
}

export function storeWhiteboardTemplateList(data) {
  localStorage.setItem(
    storage_configs.localStorage.sessions.whiteboardTemplates,
    JSON.stringify(data)
  );
}

export function removeWhiteboardTemplateList() {
  localStorage.removeItem(
    storage_configs.localStorage.sessions.whiteboardTemplates
  );
}
//#endregion

export function clearClassRoomSessionData() {
  localStorage.removeItem(storage_configs.localStorage.sessions.zoom.signature);
  localStorage.removeItem(
    storage_configs.localStorage.sessions.zoom.isZoomIntiated
  );
  localStorage.removeItem(storage_configs.localStorage.sessions.zoom.user);
  localStorage.removeItem(
    storage_configs.localStorage.sessions.zoom.participants
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.zoom.getSubsessionList
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.membercoursestreamlearnerdetailsid
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.membercoursestreamdetailid
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.current_session_id
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.current_group_id
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.current_stream_id
  );
  localStorage.removeItem(storage_configs.localStorage.sessions.show_content);
  localStorage.removeItem(
    storage_configs.localStorage.sessions.group_space_selected_tab
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.currentZoomUserId
  );
  localStorage.removeItem(storage_configs.localStorage.sessions.currentGame);
  localStorage.removeItem(storage_configs.localStorage.sessions.chats);
  localStorage.removeItem(
    storage_configs.localStorage.sessions.chatGeneralNotification
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.chatNotification
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.zoom.firstTimeInteracted
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.zoom.waitingParticipants
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.e_participants_h_idx
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.l_participants_v_idx
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.break_out_session.status
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.break_out_session.isNotification
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.current_learner_groupname
  );
  localStorage.removeItem("temp-expert-content-quiz");
  localStorage.removeItem("current-groups");
  localStorage.removeItem("group-chats");
  localStorage.removeItem(
    storage_configs.localStorage.sessions.completedSessions
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.curriculumDetail
  );
  localStorage.removeItem(
    storage_configs.localStorage.sessions.whiteboardTemplates
  );
}

export function getInitialsFromFullName(data) {
  if (data) {
    let nameArray = data.split(" ");
    if (nameArray && nameArray.length == 2) {
      let fn = nameArray[0].substring(0, 1);
      let ln = nameArray[1].substring(0, 1);
      return fn + ln;
    } else {
      let fn = nameArray[0].substring(0, 1);
      return fn;
    }
  }
}

export function getCurrentUserData() {
  let currentUserData = localStorage.getItem("user");
  if (currentUserData) {
    return JSON.parse(currentUserData);
  }
  return currentUserData;
}

export function checkIsUserNotLoggedInOrParentIsLoggedIn() {
  if (
    !getCurrentUserData() ||
    getCurrentUserData().role === conditional_configs.role.parent
  ) {
    return true;
  } else {
    return false;
  }
}

export function getUnique(array, key) {
  if (typeof key !== "function") {
    const property = key;
    key = function (item) {
      return item[property];
    };
  }
  return Array.from(
    array
      .reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
      }, new Map())
      .values()
  );
}

export function generateVideoToken(
  sdkKey = "",
  sdkSecret = "",
  topic = "",
  passWord = "",
  userIdentity = "",
  sessionKey = "",
  roleType = ""
) {
  //console.log(`zmClient Util:: ${zmClient}`)
  let signature = "";
  try {
    const iat = Math.round(new Date().getTime() / 1000);
    const exp = iat + 60 * 60 * 2;

    let currentUserData = JSON.parse(localStorage.getItem("user"));
    if (currentUserData && currentUserData.role == 3) {
      roleType = 1;
    } else {
      roleType = 0;
    }
    // Header
    const oHeader = { alg: "HS256", typ: "JWT" };
    // Payload
    const oPayload = {
      app_key: sdkKey,
      iat,
      exp,
      tpc: topic,
      pwd: passWord,
      user_identity: userIdentity,
      session_key: sessionKey,
      role_type: roleType, // role = 1 for host, 0 for attendee; a host must first start a session for attendees to join
      // topic
    };
    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(oPayload);
    signature = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, sdkSecret);
  } catch (e) {
    console.error(e);
  }
  return signature;
}

export function b64EncodeUnicode(string) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(string).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode("0x" + p1);
    })
  );
}

export function b64DecodeUnicode(string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(string)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

export function setSessionTime() {
  const now = new Date(); // Unix timestamp in milliseconds
  localStorage.setItem("session-time", now.toString());
}

export function getTimeZoneShortForm(params) {
  var timeZone = moment.tz.guess();
  var time = new Date();
  var timeZoneOffset = time.getTimezoneOffset();
  var timeZoneAbbre = moment.tz.zone(timeZone).abbr(timeZoneOffset);
  return timeZoneAbbre;
  //return params && params.courseId == 7 ? 'IST' : 'PST';
}

export function generateCommonParams(courseId) {
  const params = {
    courseId: courseId,
  };
  return params;
}

// export function convertTime(time) {
//   var timeZone = moment.tz.guess();
//   if (time) {
//     let convertedTime = moment(time, 'HH:mm').tz(timeZone).format('hh:mm A');
//     // return convertedTime + ' '+ getTimeZoneShortForm();
//     return convertedTime;
//   }
// }

export function convertTime(time) {
  var timeZone = moment.tz.guess();
  if (time) {
    ////Use this if utc time to be converted to specific time zone
    //const utcTime = moment.utc(time, 'HH:mm:ss');
    //PST time format
    const pstTime = moment.tz(time, "HH:mm:ss", "America/Los_Angeles");
    // Convert PST time to specific timezone
    const convertByTimezone = pstTime.tz(timeZone).format("hh:mm A");
    return convertByTimezone;
  }
}

export function checkIsEmdedCode(content) {
  if (content) {
    const checkStr = content.slice(0, 4);
    if (checkStr == "http") {
      return false;
    } else {
      return true;
    }
  }
  return false;
}

//COPTY TO CLIPBOARD

export async function copyToClipBoardWithMsgWithOutAlert(copyMe) {
  try {
    await navigator.clipboard.writeText(copyMe);
    // alert(msg);
  } catch (err) {
    console.log(err);
  }
}

export async function copyToClipBoardWithMsg(copyMe, msg) {
  try {
    await navigator.clipboard.writeText(copyMe);
    alert(msg);
  } catch (err) {
    console.log(err);
  }
}

export async function copyToClipBoard(copyMe) {
  try {
    await navigator.clipboard.writeText(copyMe);
    alert("Link copied!");
  } catch (err) {
    console.log(err);
  }
}

//LOAD GOOGLE SCRIPT RECAPTCHA
export async function loadScriptByURL() {
  const loadScriptByURL = (id, url, callback) => {
    const isScriptExist = document.getElementById(id);
    if (!isScriptExist) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }
    if (isScriptExist && callback) callback();
  };
  // load the script by passing the URL
  loadScriptByURL(
    "recaptcha-key",
    `https://www.google.com/recaptcha/api.js?render=${google_config.recaptcha.siteKey}`,
    function () {
      console.log("Script loaded!");
    }
  );
}

//CHARACTER VALIDATION
export function getCharacterValidationError(str) {
  return `Try a password with a minimum of 8 characters containing atleast one uppercase letter, one special character and a number`;
  //return `Your password must have at least 1 ${str} character`;
}

//CHECK IS INR
export function checkIsINR(currency) {
  if (currency == "INR") {
    return true;
  } else {
    return false;
  }
}

export function setReferralCode() {
  const query = new URLSearchParams(window.location.search);
  if (query) {
    const ref_code = query.get("ref_code");
    if (ref_code) {
      localStorage.setItem("ref_code", ref_code);
    }
  }
}

export function setContactFormReferralCode() {
  const query = new URLSearchParams(window.location.search);
  if (query) {
    const ref_code = query.get("ref_code_c");
    if (ref_code) {
      localStorage.setItem("ref_code_c", ref_code);
    }
  }
}

export function convertEpochToDate(epoch) {
  const date = new Date(epoch * 1000); // Convert epoch seconds to milliseconds
  return date.toLocaleDateString('en-US'); // Convert date to string
}

export function calculatePrice(courseId, pricePerLicense, numberOfLicenses) {
  let actualPrice = pricePerLicense * numberOfLicenses;
  let discountPercentage = 0;
  let totalPrice = actualPrice;
  let discountPrice;
  // Apply discount based on the number of licenses purchased
  if (numberOfLicenses >= 2 && numberOfLicenses < 3) {
    discountPercentage = 20;
  } else if (numberOfLicenses >= 3 && numberOfLicenses < 4) {
    discountPercentage = 30;
  } else if (numberOfLicenses >= 4) {
    discountPercentage = 50;
  }

  // Calculate the discount price
  if (discountPercentage > 0) {
    discountPrice = (actualPrice * discountPercentage) / 100;
    totalPrice = actualPrice - discountPrice;
  }

  return {
    courseId: courseId,
    actualPrice: actualPrice,
    totalPrice: totalPrice,
    discountPrice: discountPrice,
    discountPercentage: discountPercentage,
  };
}

export function validateEmail(email) {
  // Simple email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function groupBy(array, key) {
  return array.reduce((result, currentValue) => {
    // Get the value of the specified key for the current item
    const groupKey = currentValue[key];

    // If the group doesn't already exist, create an empty array for it
    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    // Add the current item to the appropriate group
    result[groupKey].push(currentValue);

    // Return the result object for the next iteration
    return result;
  }, {});
};



export async function checkAddressWithAPI(message) {
  try {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(message)}&key=YOUR KEY`);
    const data = await response.json();
    return data.results.length > 0; // Address found if results are returned
  } catch (error) {
    console.error("Error checking address:", error);
    return false;
  }
}

export function isAddressIncluded(message) {
  // Regex to match address-related terms
  const addressRegex = /\b(?:Street|St|st|str|str\.|Avenue|Ave\.|Road|Rd|rd|Ro|nagar|aven|district|dist|taluk|tk|area|kelani|nagar\.|Lane|Ln\.|Boulevard|Blvd\.|Drive|Dr\.|Way|Square|Sq\.|Court|Ct\.|Place|Pl\.|Terrace|Terr\.|Building|Bldg\.|Apartment|Apt\.|Suite|Ste\.|Unit|Block|P\.O\. Box|PO Box|Zip|Postal Code|Postcode|Pincode|Pin)\b/i;

  // Regex to detect any number (0-9)
  const numberRegex = /\d/;

  // Regex to allow valid URLs
  const urlRegex = /(https?:\/\/[^\s]+)/i;

  // Regex to allow specific code format (MEEKU-SELF-XXXXXX)
  const validCodeRegex = /\bMEEKU-SELF-\d{10,}\b/i;

  // Check if the message contains a URL
  const containsUrl = urlRegex.test(message);

  // Check if the message contains a valid code
  const containsValidCode = validCodeRegex.test(message);

  // Check if the message contains any numbers
  const containsNumber = numberRegex.test(message);

  // If the message contains a URL or valid code, allow it
  if (containsUrl || containsValidCode) {
    return false;
  }

  // If the message contains any numbers and doesn't match allowed patterns, block it
  if (containsNumber) {
    return true;
  }

  // Check if the message contains address-related terms
  return addressRegex.test(message);
}



export function checkForPlacesInfo(message) {
  const doc = nlp(message);
  const places = doc.places().out('array');
  return places.length > 0; // Check if any addresses are detected
}

