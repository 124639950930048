import logo from "../assets/img/logo_1.png";
import shortLogo from '../assets/img/short_logo.png';
import muted from '../assets/img/zoom-controls/muted.png';
import whiteDots from '../assets/img/zoom-controls/white-dots.png';
import chat from '../assets/img/zoom-controls/chat.png';
import course_topic from '../assets/img/zoom-controls/course-topic.png';
import document from '../assets/img/zoom-controls/document.png';
import notes from '../assets/img/zoom-controls/notes.png';
import whiteClose from '../assets/img/zoom-controls/white-close.png';
import phoneHangUp from '../assets/img/zoom-controls/phone-hangup.png';
import alertIcon from '../assets/img/alert-icon.png';
import sendChatWhite from '../assets/img/zoom-controls/send-chat-white.png';
import emoji from '../assets/img/zoom-controls/emoji.png';
import learner_login from '../assets/img/learner-login.jpg';
import handIcon from '../assets/img/header_img/hand_icon_img.png';
import handRaisedIcon from '../assets/img/header_img/hands_raised.png';
import fileWhite from '../assets/img/courses_img/file-white.png';
import quiz from '../assets/img/courses_img/quiz.png';
import fileImage from '../assets/img/courses_img/book_icon_img.png';
import notificationAlarm from '../assets/img/header_img/notification-alarm.png';
import maximize from "../assets/img/white-board/tabs/maximize.png";
import minimize from "../assets/img/white-board/tabs/minimize.png";

//No record found
import expertCourses from '../assets/img/no-record-found/expert-courses.jpeg'

// home-v3 images
//section-1
import maskGroup_1 from '../assets/img/home-v3/mask-group-1.jpg';
import maskGroup_2 from '../assets/img/home-v3/mask-group-2.jpg';
//section-2
import percentage_8 from '../assets/img/home-v3/8-icon.png';
import percentage_50 from '../assets/img/home-v3/50%-icon.png';
import percentage_75 from '../assets/img/home-v3/75%-icon.png';
import groupRatingIcon from '../assets/img/home-v3/group-rating.png';
import m_icon from '../assets/img/home-v3/m-icon.png';
import meeku_logo from '../assets/img/home-v3/meeku-logo.png';
import userIcon from '../assets/img/home-v3/user-icon.png';
import section_2_background from '../assets/img/home-v3/section _2_rectangle.png';
import multi_union from '../assets/img/home-v3/multi_union_icon.png';
import yellowLine from '../assets/img/home-v3/section2_line_img.png';
//section-3
import meeku_rotate_img from '../assets/img/home-v3/meeku-rotate-img-s3.png';
import focused_card_img from '../assets/img/home-v3/focused-card-img.png';
import experienced_card_img from '../assets/img/home-v3/experienced-card-img.png';
import solve_card_img from '../assets/img/home-v3/solve-card-img.png';
import team_based_card_img from '../assets/img/home-v3/team-based-card-img.png';
import section3_star_img from '../assets/img/home-v3/section3-star-img.png';
import multi_union_2 from '../assets/img/home-v3/multi_union_icon_2.png';
//section-4
import interactive_section_img_1 from '../assets/img/home-v3/interactive_section_img_1.png';
import interactive_section_img_2 from '../assets/img/home-v3/interactive_section_img_2.png';
import interactive_section_img_3 from '../assets/img/home-v3/interactive_section_img_3.png';
import interactive_section_m_icon from '../assets/img/home-v3/interactive_section_m_icon.png';
import interactive_section_mm_img from '../assets/img/home-v3/interactive_section_mm_img.png';
//section-5
import courses_offered_section_img_1 from '../assets/img/home-v3/courses_offered_section_img_1.png';
import courses_offered_section_img_2 from '../assets/img/home-v3/courses_offered_section_img_2.png';
//section-7
import multi_union_w_icon from '../assets/img/home-v3/multi_union_w_icon.png';
import m_end_icon from '../assets/img/home-v3/m_end_icon.png';
//section-8
import meeku_logo_footer from '../assets/img/home-v3/meeku-logo-footer.png';
import social_media_icon from '../assets/img/home-v3/social_media_icon.png';
// home-v3 images mobile
import backgroundRectangleImg from '../assets/img/home-v3/background-Rectangle-img.png';
import maskGroupMobile_1 from '../assets/img/home-v3/mask-group-mobile-1.png'
import groupRatingIconobile from '../assets/img/home-v3/group-rating-mobile.png';
import meeku_mobile_logo from '../assets/img/home-v3/meeku-mobile-logo.png';

import m_icon_circle from '../assets/img/home-v3/m_icon_circle.png'

// coursev2

import section_1_group_img from '../assets/img/courses-details-v2/section-1-group-img.png';
import section_1_blue_star from '../assets/img/courses-details-v2/section-1-blue-star.png';
import section_1_white_star from '../assets/img/courses-details-v2/section-1-white-star.png';
import fire_icon from '../assets/img/courses-details-v2/fire-icon.png';

import level_icon from '../assets/img/courses-details-v2/level-icon.png';
import Commitment_icon from '../assets/img/courses-details-v2/Commitment-icon.png';
import Language_icon from '../assets/img/courses-details-v2/Language-icon.png';
import Weekly_Hours_icon from '../assets/img/courses-details-v2/Weekly-Hours-icon.png';
import location_icon from '../assets/img/courses-details-v2/location-icon.png';
import Instructor_gi_icon from '../assets/img/courses-details-v2/Instructor-gi-icon.png';
import age_icon from '../assets/img/courses-details-v2/age-icon.png';

import form_course_img_2 from '../assets/img/courses-details-v2/form_course_img_2.png';
import form_course_img_1 from '../assets/img/courses-details-v2/form_course_img_1.png';
import yellow_star_img from '../assets/img/courses-details-v2/yellow_star_img.png';
import form_meeku_logo from '../assets/img/courses-details-v2/form_meeku_logo.png';

import confetti_img_pr_v2 from '../assets/img/courses-details-v2/confetti_img_pr_v2.png';
import yeah_img_pr_v2 from '../assets/img/courses-details-v2/yeah_img_pr_v2.png';

import facebook_icon from '../assets/img/courses-details-v2/facebook_icon.png';
import linked_in_icon from '../assets/img/courses-details-v2/linked_in_icon.png';
import twitter_icon from '../assets/img/courses-details-v2/twitter_icon.png';
import youtube_icon from '../assets/img/courses-details-v2/youtube_icon.png';
import music_icon from '../assets/img/courses-details-v2/music_icon.png';
import Instagram_icon from '../assets/img/courses-details-v2/Instagram-icon.png';
import faq_mm_icon from '../assets/img/home-v3/faq_m_m_icon.png';
import learner_login_img from '../assets/img/login_img/learner-login-img.png';
import parent_login_img from '../assets/img/login_img/parent-login-img.png';
import expert_login_img from '../assets/img/login_img/expert-login-img.png';
import whiteboard from '../assets/img/white-board/whiteboard.png';

// home-v4 images

import home_v4_background_img from '../assets/img/home-v4/home_v4_background_img.png';
import home_v4_background_img_2 from '../assets/img/home-v4/home_v4_background_img_2.png';
import section_2_hv4_img from '../assets/img/home-v4/section_2_hv4_img.png';
import rocket_img from '../assets/img/home-v4/rocket_img.png';
import home_v4_background_img_3 from '../assets/img/home-v4/home_v4_background_img_3.png';

import coding_adventures_img_1 from '../assets/img/home-v4/coding_adventures_img_1.png';
import coding_adventures_img_2 from '../assets/img/home-v4/coding_adventures_img_2.png';
import coding_adventures_img_3 from '../assets/img/home-v4/coding_adventures_img_3.png';
import coding_adventures_img_4 from '../assets/img/home-v4/coding_adventures_img_4.png';
import engaging_img from '../assets/img/home-v4/engaging_img.png';
import learning_pathways_img from '../assets/img/home-v4/learning_pathways_img.png';
import home_v4_background_img_4 from '../assets/img/home-v4/home_v4_background_img_4.png';

import be_a_leader_img_1 from '../assets/img/home-v4/be_a_leader_img_1.png';
import be_a_leader_img_2 from '../assets/img/home-v4/be_a_leader_img_2.png';
import students_sec_img_1 from '../assets/img/home-v4/students_sec_img_1.png';
import students_sec_img_2 from '../assets/img/home-v4/students_sec_img_2.png';
import students_sec_img_3 from '../assets/img/home-v4/students_sec_img_3.png';
import student_with_computer_img from '../assets/img/home-v4/student_with_computer_img.png';
import knife_img from '../assets/img/home-v4/knife_img.png';
import blue_with_white_arrow from '../assets/img/home-v4/blue_with_white_arrow.png';
import white_with_blue_arrow from '../assets/img/home-v4/white_with_blue_arrow.png';

import home_v4_background_img_5 from '../assets/img/home-v4/home_v4_background_img_5.png';
import meeku_blog_card_img_1 from '../assets/img/home-v4/meeku_blog_card_img_1.png';
import meeku_blog_card_img_2 from '../assets/img/home-v4/meeku_blog_card_img_2.png';
import meeku_blog_card_img_3 from '../assets/img/home-v4/meeku_blog_card_img_3.png';
import meeku_blog_card_img_4 from '../assets/img/home-v4/meeku_blog_card_img_4.png';

import calendar_icon from '../assets/img/home-v4/calendar_icon.png';
import time_left_icon from '../assets/img/home-v4/time_left_icon.png';
import card_user_icon from '../assets/img/home-v4/card_user_icon.png';
import our_success_title_icon from '../assets/img/home-v4/our_success_title_icon.png';
import video_img from '../assets/img/home-v4/video_img.png';
import our_success_stories from '../assets/img/home-v4/our_success_stories.png';
import pen_icon from '../assets/img/home-v4/pen_icon.png';
import instagram_icon from '../assets/img/home-v4/instagram_icon.png';
import facebook_icon_new from '../assets/img/home-v4/facebook_icon.png';

// Course-v4

import lets_play_learn_banner from '../assets/img/courses_img_v4/lets_play_learn_banner.png';
import rocket_nav_img from '../assets/img/courses_img_v4/rocket_nav_img.png';
import close_icon from '../assets/img/courses_img_v4/close_icon.png';
import join_with_code_text_box_background from '../assets/img/courses_img_v4/join_with_code_text_box_background.png';
import code_entered_successfully from '../assets/img/courses_img_v4/code_entered_successfully.png';
import game_card_img from '../assets/img/courses_img_v4/game_card_img.png';

import delete_icon from '../assets/img/courses_img_v4/delete_icon.png';
import plus_arrow from '../assets/img/courses_img_v4/plus_arrow.png';
import left_arrow from '../assets/img/courses_img_v4/left_arrow.png';
import send_arrow from '../assets/img/courses_img_v4/send_arrow.png';
import video_background_img from '../assets/img/courses_img_v4/video_background_img.png';
import no_messege_found_text from '../assets/img/courses_img_v4/no_messege_found_text.png';
import no_messege_icon from '../assets/img/courses_img_v4/no_messege_icon.png';

import copyLink from '../assets/img/self-learning/copy-link.png';

import meeku_video_1 from '../assets/img/videos/meeku_video_1.mp4';
import meeku_video_2 from '../assets/img/videos/meeku_video_2.mp4';

// import hi_artwork from '../assets/img/home-v3/hi_artwork.gif';

const assets = {
    meekuLogo: logo,
    shortLogo: shortLogo,
    fileImage: fileImage,
    maximize: maximize,
    minimize: minimize,
    zoomControls: {
        muted: muted,
        end: phoneHangUp
    },
    whiteDots: whiteDots,
    chat: chat,
    course_topic: course_topic,
    document: document,
    notes: notes,
    whiteClose: whiteClose,
    alertIcon: alertIcon,
    fileWhite: fileWhite,
    sendChatWhite: sendChatWhite,
    emoji: emoji,
    learner_login: learner_login,
    handIcon: handIcon,
    handRaisedIcon: handRaisedIcon,
    quiz: quiz,
    notificationAlarm: notificationAlarm,
    noRecordFound: {
        expertCourses: expertCourses
    },
    homev3: {
        //section-1
        maskGroup_1: maskGroup_1,
        maskGroup_2: maskGroup_2,

        //section-2
        percentage_8: percentage_8,
        percentage_50: percentage_50,
        percentage_75: percentage_75,
        groupRatingIcon: groupRatingIcon,
        m_icon: m_icon,
        meeku_logo: meeku_logo,
        userIcon: userIcon,


        //section-3
        meeku_rotate_img: meeku_rotate_img,
        focused_card_img: focused_card_img,
        experienced_card_img: experienced_card_img,
        solve_card_img: solve_card_img,
        team_based_card_img: team_based_card_img,
        section3_star_img: section3_star_img,
        multi_union_2: multi_union_2,

        //section-4
        interactive_section_img_1: interactive_section_img_1,
        interactive_section_img_2: interactive_section_img_2,
        interactive_section_img_3: interactive_section_img_3,
        interactive_section_m_icon: interactive_section_m_icon,
        interactive_section_mm_img: interactive_section_mm_img,

        //section-5
        courses_offered_section_img_1: courses_offered_section_img_1,
        courses_offered_section_img_2: courses_offered_section_img_2,
        //section-7
        multi_union_w_icon: multi_union_w_icon,
        m_end_icon: m_end_icon,

        //section-8
        social_media_icon: social_media_icon,
        meeku_logo_footer: meeku_logo_footer,

        // mobile
        backgroundRectangleImg: backgroundRectangleImg,
        maskGroupMobile_1: maskGroupMobile_1,
        groupRatingIconobile: groupRatingIconobile,
        meeku_mobile_logo: meeku_mobile_logo,

        section_2_background: section_2_background,
        multi_union: multi_union,
        yellowLine: yellowLine,
        faq_mm_icon: faq_mm_icon,
        m_icon_circle: m_icon_circle,
    },
    homev4: {
        //section-1
        home_v4_background_img: home_v4_background_img,
        home_v4_background_img_2: home_v4_background_img_2,
        rocket_img: rocket_img,
        section_2_hv4_img: section_2_hv4_img,
        home_v4_background_img_3: home_v4_background_img_3,
        coding_adventures_img_1: coding_adventures_img_1,
        coding_adventures_img_2: coding_adventures_img_2,
        coding_adventures_img_3: coding_adventures_img_3,
        coding_adventures_img_4: coding_adventures_img_4,
        engaging_img: engaging_img,
        learning_pathways_img: learning_pathways_img,
        home_v4_background_img_4: home_v4_background_img_4,
        be_a_leader_img_1: be_a_leader_img_1,
        be_a_leader_img_2: be_a_leader_img_2,
        students_sec_img_1: students_sec_img_1,
        students_sec_img_2: students_sec_img_2,
        students_sec_img_3: students_sec_img_3,
        student_with_computer_img: student_with_computer_img,
        knife_img: knife_img,
        blue_with_white_arrow: blue_with_white_arrow,
        white_with_blue_arrow: white_with_blue_arrow,
        home_v4_background_img_5: home_v4_background_img_5,
        meeku_blog_card_img_1: meeku_blog_card_img_1,
        meeku_blog_card_img_2: meeku_blog_card_img_2,
        meeku_blog_card_img_3: meeku_blog_card_img_3,
        meeku_blog_card_img_4: meeku_blog_card_img_4,
        calendar_icon: calendar_icon,
        time_left_icon: time_left_icon,
        card_user_icon: card_user_icon,
        our_success_title_icon: our_success_title_icon,
        video_img: video_img,
        our_success_stories: our_success_stories,
        pen_icon: pen_icon,
        instagram_icon: instagram_icon,
        facebook_icon_new: facebook_icon_new,


    },
    course_v4: {
        lets_play_learn_banner: lets_play_learn_banner,
        rocket_nav_img: rocket_nav_img,
        close_icon: close_icon,
        join_with_code_text_box_background: join_with_code_text_box_background,
        code_entered_successfully: code_entered_successfully,
        game_card_img: game_card_img,
        delete_icon: delete_icon,
        plus_arrow: plus_arrow,
        left_arrow: left_arrow,
        send_arrow: send_arrow,
        no_messege_found_text: no_messege_found_text,
        no_messege_icon: no_messege_icon,
        video_background_img: video_background_img
    },
    self_learning: {
        copyLink: copyLink
    },
    videos: {
        meeku_video_1: meeku_video_1,
        meeku_video_2: meeku_video_2
    },
    course_v2: {
        section_1_group_img: section_1_group_img,
        section_1_blue_star: section_1_blue_star,
        section_1_white_star: section_1_white_star,
        fire_icon: fire_icon,
        level_icon: level_icon,
        Commitment_icon: Commitment_icon,
        Language_icon: Language_icon,
        Weekly_Hours_icon: Weekly_Hours_icon,
        location_icon: location_icon,
        age_icon: age_icon,
        Instructor_gi_icon: Instructor_gi_icon,

        form_course_img_1: form_course_img_1,
        form_course_img_2: form_course_img_2,
        yellow_star_img: yellow_star_img,

        confetti_img_pr_v2: confetti_img_pr_v2,
        yeah_img_pr_v2: yeah_img_pr_v2,

        facebook_icon: facebook_icon,
        linked_in_icon: linked_in_icon,
        twitter_icon: twitter_icon,
        youtube_icon: youtube_icon,
        music_icon: music_icon,
        form_meeku_logo: form_meeku_logo,
        Instagram_icon: Instagram_icon

    },
    login: {
        learner_login_img: learner_login_img,
        parent_login_img: parent_login_img,
        expert_login_img: expert_login_img
    },
    whiteboard: whiteboard
};

export default assets;