import { Link as RouterLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  useParams
} from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Link, Container, Typography } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Page from "../../components/Page";
import Logo from "../../components/Logo";
// sections

import { useNavigate } from "react-router-dom";

//SERVICES
import MemberService from "../../services/member-service";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));
 
const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ActivateUser() {
  let { id } = useParams();
  
  useEffect(() => {
    console.log(id);
    let activateModle ={
      token: id
    }
    MemberService.activateUser(activateModle).then(response => {
      console.log(response.data);
    })
    .catch(e => {
      console.log(e);
    });
  }, []);

  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const navigate = useNavigate();

  const navigateToParentRegistration = () => {
    navigate("/parent-login");
  };

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />

          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {""}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to="/parent-registration"
              >
                Get started
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img
              src="/static/illustrations/illustration_login.png"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h6" gutterBottom>
              Your account activated successfully.
              <a href="" className="ps-1" onClick={navigateToParentRegistration}>
                Click here
              </a> to login
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
