import React, { useEffect } from "react";
//import Header from '../../components/header/header';
import Header from "./header";
import "./terms-and-conditions.css";
import url_configs from "../../config/url";
import Footer from "../../components/footer/footer";

export default function TermsAndConditions() {
  document.title = "Meeku | Terms and Conditions";

  return (
    <div>
      <Header screen={"home"} />
      <div className="container container-terms-and-conditions">
        <div className="body">
          <h1 className="header-terms-and-conditions">Terms and Conditions</h1>

          <h3>
            Agreement between User and{" "}
            <a href={url_configs.frontend} target="_blank">
              www.meeku.world
            </a>
          </h3>

          <p className="content-terms-and-conditions">
            Welcome to www.meeku.world. The{" "}
            <a href={url_configs.frontend} target="_blank">
              www.meeku.world
            </a>{" "}
            website (the "Site") is comprised of various web pages operated by
            Meeku LLC ("Meeku").&nbsp;
            <a href={url_configs.frontend} target="_blank">
              www.meeku.world
            </a>{" "}
            is offered to you conditioned on your acceptance without
            modification of the terms, conditions, and notices contained herein
            (the "Terms"). Your use of&nbsp;
            <a href={url_configs.frontend} target="_blank">
              www.meeku.world
            </a>{" "}
            constitutes your agreement to all such Terms. Please read these
            terms carefully, and keep a copy of them for your reference.
          </p>

          <p className="content-terms-and-conditions">
            <a href={url_configs.frontend} target="_blank">
              www.meeku.world
            </a>{" "}
            is an Online Learning platform for kids platform
          </p>

          <p className="content-terms-and-conditions">
            The purpose is to provide an enriching peer learning experience for
            kids
          </p>

          <h3>Privacy</h3>
          <p className="content-terms-and-conditions">
            Your use of www.meeku.world is subject to Meeku's Privacy Policy.
            Please review our{" "}
            <a href="/privacy-policy" target="_blank">
              Privacy Policy
            </a>
            , which also governs the Site and informs users of our data
            collection practices.
          </p>

          <h3>Electronic Communications</h3>

          <p className="content-terms-and-conditions">
            Visiting{" "}
            <a href={url_configs.frontend} target="_blank">
              www.meeku.world
            </a>{" "}
            or sending emails to Meeku constitutes electronic communications.
            You consent to receive electronic communications and you agree that
            all agreements, notices, disclosures and other communications that
            we provide to you electronically, via email and on the Site, satisfy
            any legal requirement that such communications be in writing
          </p>

          <h3>Your Account</h3>

          <p className="content-terms-and-conditions">
            If you use this site, you are responsible for maintaining the
            confidentiality of your account and password and for restricting
            access to your computer, and you agree to accept responsibility for
            all activities that occur under your account or password. You may
            not assign or otherwise transfer your account to any other person or
            entity. You acknowledge that Meeku is not responsible for third
            party access to your account that results from theft or
            misappropriation of your account. Meeku and its associates reserve
            the right to refuse or cancel service, terminate accounts, or remove
            or edit content in our sole discretion.
          </p>

          <h3>Children Under Thirteen</h3>

          <p className="content-terms-and-conditions">
            Meeku collects personally identifiable information from children
            under the age of thirteen. Meeku collects this information for the
            following reason(s):
          </p>

          <p className="content-terms-and-conditions">
            We offer educational programs for children aged 8-14 and only a
            parent or guardian can create an account for their child(ren), and
            while providing free services, we can use them for social media
            posts and marketing purposes. As the website is in its beta phase,
            we do not assume responsibility for any data loss at this stage.
          </p>

          <p className="content-terms-and-conditions">
            We provide information about our personal data practices for
            children on our home page and wherever we knowingly collect personal
            data from children on our Web site. If you are under the age of
            thirteen, you must ask your parent or guardian for permission to use
            this website. If you are a parent and you have questions regarding
            our data collection practices, please contact us using the
            information provided at the end of this Agreement. If you are under
            18, you may use{" "}
            <a href={url_configs.frontend} target="_blank">
              www.meeku.world
            </a>{" "}
            only with permission of a parent or guardian.
          </p>

          <h3>Links to Third Party Sites/Third Party Services</h3>

          <p className="content-terms-and-conditions">
            www.meeku.world may contain links to other websites ("Linked
            Sites"). The Linked Sites are not under the control of Meeku and
            Meeku is not responsible for the contents of any Linked Site,
            including without limitation any link contained in a Linked Site, or
            any changes or updates to a Linked Site. Meeku is providing these
            links to you only as a convenience, and the inclusion of any link
            does not imply endorsement by Meeku of the site or any association
            with its operators.
          </p>

          <p className="content-terms-and-conditions">
            Certain services made available via{" "}
            <a href={url_configs.frontend} target="_blank">
              www.meeku.world
            </a>{" "}
            are delivered by third party sites and organizations. By using any
            product, service or functionality originating from the
            www.meeku.world domain, you hereby acknowledge and consent that
            Meeku may share such information and data with any third party with
            whom Meeku has a contractual relationship to provide the requested
            product, service or functionality on behalf of www.meeku.world users
            and customers.
          </p>

          <h3>No Unlawful or Prohibited Use/Intellectual Property</h3>

          <p className="content-terms-and-conditions">
            You are granted a non-exclusive, non-transferable, revocable license
            to access and use{" "}
            <a href={url_configs.frontend} target="_blank">
              www.meeku.world
            </a>{" "}
            strictly in accordance with these terms of use. As a condition of
            your use of the Site, you warrant to Meeku that you will not use the
            Site for any purpose that is unlawful or prohibited by these Terms.
            You may not use the Site in any manner which could damage, disable,
            overburden, or impair the Site or interfere with any other party's
            use and enjoyment of the Site. You may not obtain or attempt to
            obtain any materials or information through any means not
            intentionally made available or provided for through the Site.
          </p>

          <p className="content-terms-and-conditions">
            All content included as part of the Service, such as text, graphics,
            logos, images, as well as the compilation thereof, and any software
            used on the Site, is the property of Meeku or its suppliers and
            protected by copyright and other laws that protect intellectual
            property and proprietary rights. You agree to observe and abide by
            all copyright and other proprietary notices, legends or other
            restrictions contained in any such content and will not make any
            changes thereto.
          </p>

          <p className="content-terms-and-conditions">
            You will not modify, publish, transmit, reverse engineer,
            participate in the transfer or sale, create derivative works, or in
            any way exploit any of the content, in whole or in part, found on
            the Site. Meeku content is not for resale. Your use of the Site does
            not entitle you to make any unauthorized use of any protected
            content, and in particular you will not delete or alter any
            proprietary rights or attribution notices in any content. You will
            use protected content solely for your personal use, and will make no
            other use of the content without the express written permission of
            Meeku and the copyright owner. You agree that you do not acquire any
            ownership rights in any protected content. We do not grant you any
            licenses, express or implied, to the intellectual property of Meeku
            or our licensors except as expressly authorized by these Terms.
          </p>

          <h3>International Users</h3>

          <p className="content-terms-and-conditions">
            The Service is controlled, operated and administered by Meeku from
            our offices within the USA. If you access the Service from a
            location outside the USA, you are responsible for compliance with
            all local laws. You agree that you will not use the Meeku Content
            accessed through www.meeku.world in any country or in any manner
            prohibited by any applicable laws, restrictions or regulations.
          </p>

          <h3>Indemnification</h3>

          <p className="content-terms-and-conditions">
            You agree to indemnify, defend and hold harmless Meeku, its
            officers, directors, employees, agents and third parties, for any
            losses, costs, liabilities and expenses (including reasonable
            attorney's fees) relating to or arising out of your use of or
            inability to use the Site or services, any user postings made by
            you, your violation of any terms of this Agreement or your violation
            of any rights of a third party, or your violation of any applicable
            laws, rules or regulations. Meeku reserves the right, at its own
            cost, to assume the exclusive defense and control of any matter
            otherwise subject to indemnification by you, in which event you will
            fully cooperate with Meeku in asserting any available defenses.
          </p>

          <h3>Class Action Waiver</h3>
          <p className="content-terms-and-conditions">
            Any arbitration under these Terms and Conditions will take place on
            an individual basis; class arbitrations and
            class/representative/collective actions are not permitted. THE
            PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY
            IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE
            PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION
            AGAINST THE OTHER. Further, unless both you and Meeku agree
            otherwise, the arbitrator may not consolidate more than one person's
            claims, and may not otherwise preside over any form of a
            representative or class proceeding.
          </p>

          <h3>Liability Disclaimer</h3>
          <p className="content-terms-and-conditions">
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
            AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
            ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
            MEEKU LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES
            IN THE SITE AT ANY TIME.
          </p>

          <p className="content-terms-and-conditions">
            MEEKU LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
            SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF
            THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
            CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
            PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
            WARRANTY OR CONDITION OF ANY KIND. MEEKU LLC AND/OR ITS SUPPLIERS
            HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
            INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
          </p>

          <p className="content-terms-and-conditions">
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            MEEKU LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT,
            PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
            WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE,
            DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE
            OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE
            SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
            SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT
            OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
            STRICT LIABILITY OR OTHERWISE, EVEN IF MEEKU LLC OR ANY OF ITS
            SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE
            SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
            OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
            LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY
            PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE
            AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
          </p>

          <h3>Termination/Access Restriction</h3>

          <p className="content-terms-and-conditions">
            Meeku reserves the right, in its sole discretion, to terminate your
            access to the Site and the related services or any portion thereof
            at any time, without notice. To the maximum extent permitted by law,
            this agreement is governed by the laws of the State of California
            and you hereby consent to the exclusive jurisdiction and venue of
            courts in California in all disputes arising out of or relating to
            the use of the Site. Use of the Site is unauthorized in any
            jurisdiction that does not give effect to all provisions of these
            Terms, including, without limitation, this section.
          </p>
          <p className="content-terms-and-conditions">
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and Meeku as a result of this
            agreement or use of the Site. Meeku's performance of this agreement
            is subject to existing laws and legal process, and nothing contained
            in this agreement is in derogation of Meeku's right to comply with
            governmental, court and law enforcement requests or requirements
            relating to your use of the Site or information provided to or
            gathered by Meeku with respect to such use. If any part of this
            agreement is determined to be invalid or unenforceable pursuant to
            applicable law including, but not limited to, the warranty
            disclaimers and liability limitations set forth above, then the
            invalid or unenforceable provision will be deemed superseded by a
            valid, enforceable provision that most closely matches the intent of
            the original provision and the remainder of the agreement shall
            continue in effect.
          </p>
          <p className="content-terms-and-conditions">
            Unless otherwise specified herein, this agreement constitutes the
            entire agreement between the user and Meeku with respect to the Site
            and it supersedes all prior or contemporaneous communications and
            proposals, whether electronic, oral or written, between the user and
            Meeku with respect to the Site. A printed version of this agreement
            and of any notice given in electronic form shall be admissible in
            judicial or administrative proceedings based upon or relating to
            this agreement to the same extent and subject to the same conditions
            as other business documents and records originally generated and
            maintained in printed form. It is the express wish to the parties
            that this agreement and all related documents be written in English.
          </p>

          <h3>Changes to Terms</h3>
          <p className="content-terms-and-conditions">
            Meeku reserves the right, in its sole discretion, to change the
            Terms under which{" "}
            <a href={url_configs.frontend} target="_blank">
              www.meeku.world
            </a>{" "}
            is offered. The most current version of the Terms will supersede all
            previous versions. Meeku encourages you to periodically review the
            Terms to stay informed of our updates.
          </p>

          <h3>Contact Us</h3>
          <p className="content-terms-and-conditions">
            Meeku welcomes your questions or comments regarding the Terms:
          </p>
          <p className="content-terms-and-conditions">Meeku LLC</p>
          <p className="content-terms-and-conditions">
            San jose, California 95121
          </p>
          <p className="content-terms-and-conditions">
            Email Address:{" "}
            <a href="mailto:admin@meeku.world">admin@meeku.world</a>
          </p>
          <p className="content-terms-and-conditions">
            Effective as of February 02, 2024
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
