import React from "react";
import { useState, useEffect } from "react";
import "./home-v4.css";
import assets from "../../config/assets";
import Header from "./header";
import { Button, Typography, Container, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FooterV4 from "./footer-v4/footer-v4";
import conditional_configs from "../../config/conditional_config";
import { useNavigate } from "react-router-dom";
import { setReferralCode } from "../../utils/util";

export default function Homev4() {

    const home_v4_background_img = {
        backgroundImage: `url(${assets.homev4.home_v4_background_img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "40px",
        paddingBottom: "250px",
    };
    const home_v4_background_img_2 = {
        backgroundImage: `url(${assets.homev4.home_v4_background_img_2})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "10px",
        paddingBottom: "0px",
    };

    const home_v4_background_img_3 = {
        backgroundImage: `url(${assets.homev4.home_v4_background_img_3})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "10px",
        paddingBottom: "100px",
    };

    const home_v4_background_img_5 = {
        backgroundImage: `url(${assets.homev4.home_v4_background_img_5})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "10px",
        paddingBottom: "100px",
    };

    const navigate = useNavigate();

    useEffect(() => {
        setReferralCode();
    }, []);

    return (
        <div className="main-container-hv3">
            <Header></Header>
            {/* section-1-background-hv4 */}
            <div className="section-1-background-hv4" style={home_v4_background_img}>
                <div className="section-1-container-hv4 mt-5">
                    <div className="container">
                        <div className="row ms-0 me-0">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="mt-5">
                                    <p className="desktop-section-1-title-hv4">
                                        EMPOWER POTENTIAL CODE, CREATE, LEAD.
                                    </p>

                                    <div className="d-flex align-items-center">
                                        <button className="start-for-free-hv4 mt-3 mb-2" onClick={() =>
                                            navigate(conditional_configs.tempCoursesUrl.freeCoursesUrl)
                                        }>
                                            <p className="mb-0 me-2 btn-text-hv4">Start for free</p>  <img
                                                className="" width={40}
                                                src={assets.homev4.white_with_blue_arrow}
                                            ></img>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-2-container-hv4 */}
            <div className="section-2-container-hv4" style={home_v4_background_img_2}>
                <p className="desktop-section-2-title-hv4">JOURNEY INTO CODING WONDERLAND</p>

                <div className="container pb-5">
                    <div className="row ms-0 me-0">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="d-flex justify-content-center mb-3">
                                <div className="video-container-home-vhv4">

                                    <iframe
                                        src={assets.videos.meeku_video_1}
                                        frameborder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen
                                        width="100%"
                                        height="300px"
                                        title="Soccer"
                                    ></iframe>
                                    <p className="video-text-hv4 mb-0">Children Experience ExtraOrdinary!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <p className="section-2-dec-hv4 pt-2">Embark on an exciting adventure as we delve into the enchanting realm of coding! As whimsical animations dance across the screen, children and parents alike will be transported into a world where imagination meets technology. Watch as young learners discover the magic of coding through interactive games, colorful characters, and engaging challenges. Guided by friendly mentors, they navigate through the twists and turns of coding concepts with enthusiasm.</p>
                            <p className="section-2-dec-hv4">Get ready to be inspired as we showcase the endless possibilities that await within our coding wonderland.</p>
                            <div className="d-flex justify-content-end">
                                <img
                                    className=""
                                    src={assets.homev4.rocket_img}
                                ></img>
                            </div>
                        </div>
                    </div>

                    <p className="desktop-section-2-title-hv4 mb-0">STRUGGLING WITH STEM? BORED OF LEARNING?</p>
                    <p className="section-2-dec-hv4">Is your child spending more time mindlessly scrolling through TikTok and YouTube than engaging with meaningful learning experiences? It's time for a change. Welcome to our mission-driven learning community, where we understand the challenges of traditional learning and offer a solution that engages and inspires.</p>
                </div>

                <div className="">
                    <img
                        className="w-100"
                        src={assets.homev4.home_v4_background_img_3}
                    ></img>
                </div>
            </div>
            {/* section-3-container-hv4 */}
            <div className="section-3-container-hv4">
                <div className="container pt-5 pb-5">
                    <div className="row ms-0 me-0">

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <p className="desktop-section-3-title-hv4 mt-0 mb-0">CODING ADVENTURES</p>
                            <p className="section-2-dec-hv4">Dive into our diverse range of missions tailored to ignite your passion for coding and problem-solving. From beginner-friendly challenges to advanced projects, there's something for everyone to embark on.</p>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="d-flex justify-content-center">
                                <div className="card coding-adven-card-1 me-4">
                                    <img
                                        className="coding-adven-card-image"
                                        src={assets.homev4.coding_adventures_img_1}
                                    ></img>
                                </div>

                                <div className="card coding-adven-card-2">
                                    <img
                                        className="coding-adven-card-image"
                                        src={assets.homev4.coding_adventures_img_2}
                                    ></img>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row mt-3 ms-0 me-0">

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <p className="desktop-section-3-title-hv4 mt-0 mb-0">DESIGN SOLUTIONS </p>
                            <p className="section-2-dec-hv4">Tackle real-time challenges and unleash creativity to design innovative solutions. From crafting user-friendly interfaces to optimizing algorithms, ideas have the power to make a difference.</p>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="d-flex justify-content-center">
                                <div className="card coding-adven-card-3 me-4">
                                    <img
                                        className="coding-adven-card-image"
                                        src={assets.homev4.coding_adventures_img_3}
                                    ></img>
                                </div>

                                <div className="card coding-adven-card-4">
                                    <img
                                        className="coding-adven-card-image"
                                        src={assets.homev4.coding_adventures_img_4}
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="">
                    <img
                        className="w-100"
                        src={assets.homev4.home_v4_background_img_4}
                    ></img>
                </div>
            </div>

            {/* section-4-container-hv4 */}
            <div className="section-4-container-hv4">
                <div className="container pb-5">
                    <div className="row ms-0 me-0">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                            <div className="d-flex justify-content-center">
                                <img
                                    className="coding-adven-card-image"
                                    src={assets.homev4.engaging_img}
                                ></img>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12 col-12 mt-3">
                            <div>
                                <p className="desktop-section-3-title-hv4 mt-0 mb-0">ENGAGING CURRICULUM</p>
                                <p className="section-2-dec-hv4">Our carefully crafted curriculum of 56k learning outcomes ensures your child gains valuable skills and knowledge with each mission completed. Whether they're mastering a new programming language or honing their design abilities, tangible growth awaits.</p>
                            </div>

                            <div>
                                <p className="desktop-section-3-title-hv4 mt-0 mb-0">SUPPORTIVE COMMUNITY</p>
                                <p className="section-2-dec-hv4">Empower others by sharing creations with our supportive community. Whether it's a ground breaking app or an elegant piece of code, contributions inspire and elevate everyone around.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-5-container-hv4 */}
            <div className="section-5-container-hv4">
                <div className="container pb-5">
                    <div className="row ms-0 me-0">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="">
                                <img
                                    className="card_learning_pathways_img"
                                    src={assets.homev4.learning_pathways_img}
                                ></img>

                            </div>
                        </div>

                        <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                            <div>
                                <p className="desktop-section-3-title-hv4">LEARNING PATHWAYS</p>
                                <p className="section-2-dec-hv4">Dive into a realm where imagination meets technology with our robotics games! In this dynamic section of our kids' code app, children embark on thrilling adventures, designing and programming their own virtual robots. Through interactive challenges and exciting missions, they learn essential coding concepts while honing their problem-solving skills. From exploring new worlds to conquering obstacles, each game sparks creativity and fuels a passion for robotics. Join us as we empower young innovators to unleash their potential and become the next generation of robot builders and programmers!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-6-container-hv4 */}
            <div className="section-6-container-hv4">
                <div className="container pt-5 pb-5">
                    <div className="row ms-0 me-0">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-4 pb-4">
                            <div className="card be-a-leader-card-hv4">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <div className="be_a_leader_div">
                                            <p className="sub-title-hv4">BE A LEADER</p>
                                            <p className="dec-text-hv4">Seize the opportunity to lead your own mission and inspire others to reach their full potential. Showcase expertise, share knowledge, and make a meaningful impact within our community.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-center">
                                        <img
                                            className=""
                                            src={assets.homev4.be_a_leader_img_1}
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-4 pb-4">
                            <div className="card be-a-leader-card-hv4-2">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-center">
                                        <img
                                            className=""
                                            src={assets.homev4.be_a_leader_img_2}
                                        ></img>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <div className="be_a_leader_div_2">
                                            <p className="sub-title-hv4">INVITE YOUR FRIENDS</p>
                                            <p className="dec-text-hv4">Learning is more fun with friends! Invite peers to join this exciting adventure in coding and design. Together, they'll collaborate, brainstorm, and unleash creativity to solve real-world problems.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-7-container-hv4 */}

            <div className="section-7-container-hv4">
                <div className="container pt-5">
                    <div className="row justify-content-center align-items-center ms-0 me-0">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 pt-4 pb-4">
                            <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    <img
                                        className=""
                                        src={assets.homev4.students_sec_img_1}
                                    ></img>
                                </div>

                                <div>
                                    <p className="section-7-title">1000 +</p>
                                    <p className="section-7-sub-title">Students</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 pt-4 pb-4">
                            <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    <img
                                        className=""
                                        src={assets.homev4.students_sec_img_2}
                                    ></img>
                                </div>

                                <div>
                                    <p className="section-7-title">OUTCOMES</p>
                                    <p className="section-7-sub-title">40,000 accomplished</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 pt-4 pb-4">
                            <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    <img
                                        className=""
                                        src={assets.homev4.students_sec_img_3}
                                    ></img>
                                </div>

                                <div>
                                    <p className="section-7-title">1000 +</p>
                                    <p className="section-7-sub-title">Missions conquered</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-8-container-hv4 */}

            <div className="section-8-container-hv4">
                <div className="container pt-5 pb-5">
                    <div className="row ms-0 me-0">
                        <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div className="d-flex justify-content-center">
                                <div className="video-container-home-vhv4-2">
                                    <iframe
                                        src={assets.videos.meeku_video_2}
                                        frameborder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen
                                        width="100%"
                                        height="250px"
                                        title="Soccer"
                                    ></iframe>
                                    <p className="video-text-hv4 mb-0">EXCITED STUDENTS USING MEEKU</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div className="pt-4">
                                <p className="desktop-section-3-title-hv4 mb-0">1000’s OF STUDENTS</p>
                                <p className="section-2-dec-hv4">Become part of a vibrant community of like-minded individuals who are passionate about learning and growing together. Forge connections, exchange ideas, and support each other on this educational journey.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row ms-0 me-0 mt-3">
                        <div className="col-lg-9 col-md-6 col-sm-12 col-12">
                            <div>
                                <p className="desktop-section-3-title-hv4 mb-0">1000 MISSIONS COMPLETED</p>
                                <p className="section-2-dec-hv4">Join the ranks of our accomplished learners who have conquered challenges and emerged as skilled problem-solvers. Your child's journey to mastery begins here.</p>
                            </div>
                            {/* <div className="d-flex justify-content-end">
                                <img width={60}
                                    className=""
                                    src={assets.homev4.knife_img}
                                ></img>
                            </div> */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="">
                                <img
                                    className="card_learning_pathways_img"
                                    src={assets.homev4.student_with_computer_img}
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* section-9-container-hv4 */}

            <div className="section-9-container-hv4">
                <div className="container pt-5 pb-5">
                    <div className="row ms-0 me-0 mt-5 mb-5">
                        <div className="card section-9-card-hv4">
                            <p className="ready-to-start-text-hv4">READY TO START YOUR CODING ADVENTURE</p>
                            <p className="ready-to-start-dec-hv4 mb-0">Join us today and embark on a journey of discovery, learning, and innovation. Together, let's shape the future with a passion for coding and design. See you on the inside!</p>
                            <div className="d-flex justify-content-center align-items-center">
                                <button className="sign-up-btn-hv4 mt-3 mb-2" onClick={() =>
                                    navigate(conditional_configs.tempCoursesUrl.freeCoursesUrl)
                                }>
                                    <p className="mb-0 me-2 btn-text-hv4">Start for free</p>  <img
                                        className="" width={40}
                                        src={assets.homev4.blue_with_white_arrow}
                                    ></img>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* section-10-background-hv4 */}
            <div className="section-10-background-hv4" style={home_v4_background_img_5}>
                <p className="desktop-section-10-title-hv4">MEEKU’S BLOG HUB</p>
                <div className="container pt-2 pb-5">
                    <div className="row ms-0 me-0">
                        <div className="col-lg-5 col-md-5 col-sm-12 col-12 p-lg-0">
                            <div className="card card_meeku_blog">
                                <img
                                    className="meeku_blog_card_img_1"
                                    src={assets.homev4.meeku_blog_card_img_1}
                                ></img>

                                <div className="d-flex align-items-center mt-3">
                                    <div className="">
                                        <p className="d-flex align-items-center card-time-text-hv4 mb-0"><img
                                            className="me-2" width={16}
                                            src={assets.homev4.calendar_icon}
                                        ></img>July 12, 2024 </p>
                                    </div>
                                    <div className="ms-auto">
                                        <p className="d-flex align-items-center card-time-text-hv4 mb-0"><img
                                            className="me-2" width={16}
                                            src={assets.homev4.time_left_icon}
                                        ></img>2 min read </p>
                                    </div>
                                </div>
                                <p className="card_blog_card_title mt-2">Meeku's Scratch Spectacular</p>
                                <p className="card_blog_card_dec">Join Meeku in a Scratch-tastic adventure where we'll learn how to create our very own animated stories and games using Scratch!</p>
                                <div className="d-flex align-items-center mt-2 mb-2">
                                    <div className="">
                                        <p className="d-flex align-items-center card-time-text-hv4 mb-0"><img
                                            className="me-2" width={24}
                                            src={assets.homev4.card_user_icon}
                                        ></img>Avila Nancy </p>
                                    </div>
                                    <div className="ms-auto">
                                        <button className="coding_btn_hv4">CODING</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                            <div className="card card_meeku_blog_1">
                                <div className="row justify-content-center align-items-center ms-0 me-0">
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                                        <img
                                            className="meeku_blog_card_img_1"
                                            src={assets.homev4.meeku_blog_card_img_2}
                                        ></img>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p className="card_blog_card_title mb-0">Python Party with Meeku</p>
                                        <p className="card_blog_card_dec">Let's hang out with Meeku and dive into the fascinating world of Python! Get ready to write some cool code.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card card_meeku_blog_1 mt-1">
                                <div className="row justify-content-center align-items-center ms-0 me-0">
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                                        <img
                                            className="meeku_blog_card_img_1"
                                            src={assets.homev4.meeku_blog_card_img_3}
                                        ></img>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p className="card_blog_card_title mb-0">Code Quest: Journey into Game Design</p>
                                        <p className="card_blog_card_dec">Join on an epic quest to design. Let your imagination run wild as we bring our game ideas to life!</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card card_meeku_blog_1 mt-1">
                                <div className="row justify-content-center align-items-center ms-0 me-0">
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                                        <img
                                            className="meeku_blog_card_img_1"
                                            src={assets.homev4.meeku_blog_card_img_4}
                                        ></img>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p className="card_blog_card_title mb-0">Meeku's Coding Comics</p>
                                        <p className="card_blog_card_dec">Check out Meeku's hilarious comics featuring coding adventures, mishaps, and triumphs!</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            {/* section-11-background-hv4 */}
            <div className="section-11-background-hv4">
                <div className="container pt-2 pb-5">
                    <div className="pt-4">
                        <img
                            className="meeku_blog_card_img_1"
                            src={assets.homev4.our_success_title_icon}
                        ></img>
                        <p className="desktop-section-11-title-hv4">OUR SUCCESS STORIES</p>
                    </div>
                    <div className="row ms-0 me-0">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-2">
                            <div className="card card_our_success">
                                <p className="card_blog_card_dec">Meeku has transformed my child's interest in learning. The engaging missions and supportive community have made coding fun and exciting. I highly recommend it to any parent looking to empower their child with valuable skills for the future.</p>
                            </div>
                            <div>
                                <img
                                    className="video_img_hv4 d-none d-sm-block"
                                    src={assets.homev4.video_img}
                                ></img>

                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-2">
                            <div className="d-flex justify-content-center">
                                <img width={"50%"}
                                    className=""
                                    src={assets.homev4.our_success_stories}
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* section-12-background-hv4 */}
            <div className="section-12-background-hv4">
                <div className="container pt-2 pb-5">
                    <div className="pt-4">
                        <p className="desktop-section-12-title-hv4">GOT A QUESTION? CHECK HERE</p>
                    </div>



                    <div className="">
                        <div className="row ms-0 me-0 mt-4">

                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4 m-0"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3"
                                                src={assets.homev4.pen_icon}
                                            ></img>          What kind of programs does Meeku offer?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>

                                        <Typography className="Accordion-dec-hv4">
                                            At Meeku, we provide children with interdisciplinary
                                            STEM-based collaborative, team-based learning missions
                                            that emphasize practical, real-world skills development.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>



                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3"
                                                src={assets.homev4.pen_icon}
                                            ></img>       How is learning structured?
                                        </Typography>

                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            Our current program spans over nine-weeks and is titled
                                            'Problem Solving with Robotics. It is divided into three
                                            modules - Introductory, Learning and Execution. Each
                                            module is for three weeks with one hour of class per week.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>



                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3"
                                                src={assets.homev4.pen_icon}
                                            ></img>         What is the duration of each session?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            One hour per week under the guidance of a qualified
                                            instructor.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>



                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3"
                                                src={assets.homev4.pen_icon}
                                            ></img>    What is the purpose of those programs?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            To experience real-time problem-solving with a team of
                                            curious learners, and develop your logical thinking and
                                            coding skills with Meeku's program
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>















                            <div className="mb-3">
                                <Accordion className="Accordion-Frequently-sec-faq">
                                    <AccordionSummary
                                        className="Accordion-Summar-Frequently-hv4"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="Accordion-title-hv4">
                                            <img
                                                className="me-3"
                                                src={assets.homev4.pen_icon}
                                            ></img>            Is there a free trial?
                                        </Typography>
                                    </AccordionSummary>
                                    <hr className="mt-0"></hr>
                                    <AccordionDetails>
                                        <Typography className="Accordion-dec-hv4">
                                            We have a free demo class for 30 minutes. Sign up for a
                                            test-drive and experience Meeku for yourself! One hour
                                            per week under the guidance of a qualified instructor.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                        </div>
                    </div>

                </div>

            </div>


            <FooterV4></FooterV4>

        </div>
    )
}