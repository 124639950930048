import React from 'react';
import './footer.css'
//icons
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
//icon end
import meta_data from '../../../config/meta_data';

export default function footer() {
  return (
    <div>
         <div className="footer_session">
          <footer className="nb-footer">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="footer-info-single">
                    <h2 className="footer_title">Course</h2>
                    <ul className="list-unstyled">
                      <li>
                        <a href="" title="" className="footer_list_a_text">
                          Robotics
                        </a>
                      </li>
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          Mythololy
                        </a>
                      </li>
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          About Universe
                        </a>
                      </li>
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          Programming
                        </a>
                      </li>
                      <li>
                        <a href="#/" title="" className="footer_list_a_text">
                          Coding for kids
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6">
                  <div className="footer-info-single">
                    <h2 className="footer_title">categories</h2>
                    <ul className="list-unstyled">
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          STEM
                        </a>
                      </li>
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          Biology
                        </a>
                      </li>
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          Technology
                        </a>
                      </li>
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          Chemistry
                        </a>
                      </li>
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          Physics
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6">
                  <div className="footer-info-single">
                    <h2 className="footer_title">website</h2>
                    <ul className="list-unstyled">
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          About us
                        </a>
                      </li>
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          Careers
                        </a>
                      </li>
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          Become an expert
                        </a>
                      </li>
                      <li>
                        <a href="#" title="" className="footer_list_a_text">
                          Legal Policies
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6">
                  <div className="footer-info-single">
                    <h2 className="footer_title">{meta_data.company} Support</h2>
                    <p className="m-0 mobile_num_text">Mobile:+1239 293 2898</p>
                    <p className="m-0 email_text">Email:support@meeku.world</p>
                    <div className="d-flex justyfy-content-center mt-3">
                      <FacebookRoundedIcon
                        sx={{
                          color: "white",
                          fontSize: 40,
                          marginRight: "10px",
                        }}
                      />
                      <InstagramIcon sx={{ color: "white", fontSize: 40 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section className="copyright">
              <p className="copyright_text m-0">Copyright 2022 Meeku.world</p>
            </section>
          </footer>
        </div>
    </div>
  )
}

