import http from "../router/http-common";
import authHeader from "./auth/auth-header";
import api_path from "../config/api";
import { getCurrentUserData } from "../utils/util";

const getCourseDetailByLearnerId = async (learnerId) => {
    const response = await http.get(
        api_path.getCourseDetailByLearnerId + learnerId,
        { headers: authHeader() }
    );
    return response.data;
};

const getCourseDetailByParentId = async (parentId) => {
    const response = await http.get(
        api_path.getCourseDetailByParentId + parentId,
        { headers: authHeader() }
    );
    return response.data;
};

const getBoardsTemplatesById = async (id) => {
    const response = await http.get(
        api_path.getBoardsTemplatesById + id,
        { headers: authHeader() }
    );
    return response.data;
};

const getSelfLearningGroupDetailByCode = async (code) => {
    const response = await http.get(
        api_path.getSelfLearningGroupDetailByCode + code,
        { headers: authHeader() }
    );
    return response.data;
};

const getSelfLearningGroupDetailByCourseIdAndLearnerId = async (couse_id, learner_id, game_id, type) => {
    const response = await http.get(
        api_path.getSelfLearningGroupDetailByCourseIdAndLearnerId + couse_id + '&learner_id=' + learner_id + '&game_id=' + game_id + '&type=' + type,
        { headers: authHeader() }
    );
    return response.data;
};

const shareCode = async (model) => {
    const response = await http.post(
        api_path.shareCode, model,
        { headers: authHeader() }
    );
    return response.data;
};

const joinWithCode = async (model) => {
    const response = await http.post(
        api_path.joinWithCode, model,
        { headers: authHeader() }
    );
    return response.data;
};

const openBoards = async (model) => {
    const response = await http.post(
        api_path.openBoards, model,
        { headers: authHeader() }
    );
    return response.data;
};

const getIntractedFriends = async () => {
    const response = await http.get(
        api_path.getIntractedFriends + getCurrentUserData().id,
        { headers: authHeader() }
    );
    return response.data;
};

const sendPrivateMessage = async (model) => {
    const response = await http.post(
        api_path.sendPrivateMessage, model,
        { headers: authHeader() }
    );
    return response.data;
};

const getMyPrivateMessageByRecipientId = async (recipient_id) => {
    const response = await http.get(
        api_path.getMyPrivateMessageByRecipientId + getCurrentUserData().id + '&recipient_id=' + recipient_id,
        { headers: authHeader() }
    );
    return response.data;
};

const inviteFriendToChat = async (model) => {
    const response = await http.post(
        api_path.inviteFriendToChat, model,
        { headers: authHeader() }
    );
    return response.data;
};

const acceptChatInvite = async (model) => {
    const response = await http.post(
        api_path.acceptChatInvite, model,
        { headers: authHeader() }
    );
    return response.data;
};

const rejectChatInvite = async (model) => {
    const response = await http.post(
        api_path.rejectChatInvite, model,
        { headers: authHeader() }
    );
    return response.data;
};

const getMyInvites = async () => {
    const response = await http.get(
        api_path.getMyInvites + getCurrentUserData().id,
        { headers: authHeader() }
    );
    return response.data;
};

const getMyInvitesByParent = async () => {
    const response = await http.post(
        api_path.getMyInvitesByParent, { email: getCurrentUserData().email },
        { headers: authHeader() }
    );
    return response.data;
};

const getChatFriends = async () => {
    const response = await http.get(
        api_path.getChatFriends + getCurrentUserData().id,
        { headers: authHeader() }
    );
    return response.data;
}

const removeLearnerFromGroup = async (model) => {
    const response = await http.post(
        api_path.removeLearnerFromGroup, model,
        { headers: authHeader() }
    );
    return response.data;
};

const getLeaderBoardForGamesByKid = async () => {
    const response = await http.get(
        api_path.getLeaderBoardForGamesByKid + getCurrentUserData().id,
        { headers: authHeader() }
    );
    return response.data;
};

const createSelfLearningLearners = async (model) => {
    const response = await http.post(
        api_path.createSelfLearningLearners, model,
        { headers: authHeader() }
    );
    return response.data;
};

const SelfLearningService = {
    getCourseDetailByLearnerId,
    getSelfLearningGroupDetailByCourseIdAndLearnerId,
    getSelfLearningGroupDetailByCode,
    getBoardsTemplatesById,
    shareCode,
    joinWithCode,
    openBoards,
    getIntractedFriends,
    sendPrivateMessage,
    getMyPrivateMessageByRecipientId,
    inviteFriendToChat,
    getMyInvites,
    getChatFriends,
    acceptChatInvite,
    getCourseDetailByParentId,
    removeLearnerFromGroup,
    getMyInvitesByParent,
    rejectChatInvite,
    getLeaderBoardForGamesByKid,
    createSelfLearningLearners
};
export default SelfLearningService;