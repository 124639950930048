import React, { useEffect, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Card,
} from "@mui/material";
import { SnackbarProvider, useSnackbar } from 'notistack';
import './self-learning-success-page.css';

//CONFIGS
import assets from "../../config/assets";

//SERVICES
import CourseService from "../../services/courses-service";
import { getCurrentUserData } from "../../utils/util";
import PaymentService from "../../services/payment-service";
import navigate_route_config from "../../config/navigate_route_config";

const SelfLearningSuccessPage = () => {
    const [courseInfo, setCourseInfo] = useState();
    const navigate = useNavigate();

    const navigateTo =(link)=>{
        navigate(link);
    }

    useEffect(() => {
        // Function to get query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    
    // Get specific query parameter by name
    const sessionId = queryParams.get('session_id');
    // Log the retrieved query parameter
    console.log('Session ID:', sessionId);
    const data ={
        sessionId: sessionId,
        parentId: getCurrentUserData().id
    }
    PaymentService.completeTheSubscriptionPayment(data).then(response => {
        console.log('completeTheSubscriptionPayment::', response);
       
      })
        .catch(e => {
          console.log(e);
        });

      }, []);

    return (
        <div className="h-100">
            <div className="row h-100 ms-0 me-0">
                <div className="col-lg-6 left-column-sp p-0" style={{ zIndex: "1" }}>
                    <div className="left-column-sp-section">
                        <div className="">
                            <img src={assets.course_v2.form_meeku_logo} className='meeku-icon-sp'></img>
                        </div>
                        <div className='mt-4'>
                            <img src={assets.course_v2.section_1_group_img} className=''></img>
                        </div>
                        {/* <p className="Coding-With-Title-sp mt-3">Problem Solving Using Robotics</p> */}
                    </div>
                    <img src={assets.homev3.m_icon} className='m-icon-sp'></img>
                    <div className="d-flex justify-content-end">
                        <img src={assets.course_v2.yellow_star_img} className='yellow_star_img_sp me-3'></img>
                    </div>
                </div>
                <div className="col-lg-6 right-column-pr-sp p-0">
                    <div className="right-column-pr-sp-section mt-4">
                        <Card className="parent-reg-card-sp mt-2">
                            <Box>
                                <div className="d-flex justify-content-center mt-2">
                                    <img src={assets.course_v2.confetti_img_pr_v2} className='confetti_img_pr_sp' style={{ width: "85px" }}></img>
                                </div>
                                <div className="d-flex justify-content-center mt-2">
                                    <img src={assets.course_v2.yeah_img_pr_v2} className='yeah_img_pr_sp' style={{ width: "185px" }}></img>
                                </div>
                                {courseInfo?.isFree == true ?
                                    <p className="registration-complete-text-sp pt-4">Your FREE course registration is complete</p> :
                                    <p className="registration-complete-text-sp pt-4">Your course registration is complete</p>}

                                <div>
                                    <p className="complete-qu-text-sp m-0">What’s next?</p>
                                    <p className="complete-an-text-sp m-0">We sent you the detailed instructions on how to join the online class to your registered email.</p>
                                </div>

                                <div className="mt-3">
                                    <p className="complete-qu-text-sp m-0">Talk to your course advisor</p>
                                    <p className="complete-an-text-sp m-0">admin@meeku.in</p>
                                </div>

                                <div className="d-flex mt-4 pb-3">
                                    <div className='Submit-btn-sp' onClick={(e)=>navigateTo(navigate_route_config.parent.self_learning)}>
                                        <span>Go to Dashboard</span>
                                    </div>
                                </div>
                            </Box>
                        </Card>
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                        <img src={assets.homev3.section3_star_img} className='section3_star_img_sp me-3'></img>
                    </div>

                    <div className="d-flex justify-content-start mt-2">
                        <img src={assets.homev3.interactive_section_mm_img} className='interactive_section_mm_img_sp'></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelfLearningSuccessPage;
