import React from "react";
import assets from "../../../../config/assets";
import { Container } from "@mui/material";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "./courses-description-v4.css";
import { useNavigate } from 'react-router-dom';
import storage_configs from '../../../../config/storage-config'
import { getCurrentUserData } from "../../../../utils/util";
import conditional_configs from "../../../../config/conditional_config";

export default function CoursesDescriptionV4() {

  const [currentSection, setCurrentSection] = useState('features');

  const [selectedDescriptionDetails, setSelectedDescriptionDetails] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem(storage_configs.localStorage.sessions.selectedCardDescriptionDetails);

    if (storedData) {
      setSelectedDescriptionDetails(JSON.parse(storedData));
    } else {
      if (getCurrentUserData().role === conditional_configs.role.learner) {
        navigate('/learner/missions');
      } else if (getCurrentUserData().role === conditional_configs.role.parent) {
        navigate('/parent/self-learning');
      }
    }
  }, []);


  const navigate = useNavigate();

  const { gameName } = useParams();

  useEffect(() => {
    const sections = ['features', 'howToPlay', 'test'];
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % sections.length;
      setCurrentSection(sections[currentIndex]);
    }, 5000); // Change section every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleBack = () => {
    // Remove selected whiteboard from localStorage
    localStorage.removeItem(storage_configs.localStorage.sessions.selectedCardDescriptionDetails);

    // Navigate back to the previous page
    navigate(-1);
  };




  return (
    <div className="courses-description-div-v4">
      <Container>
        <div>
          <p className="courses-description-title-v4">{selectedDescriptionDetails?.name}</p>
        </div>

        <div className="courses-video-container-v4 mt-5 mb-4">
          <div className="video-container-div-cv4">
            <iframe
              src={selectedDescriptionDetails?.media_url ? selectedDescriptionDetails.media_url : "https://player.vimeo.com/video/957722739"}
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              width="100%"
              height="400px"
              title="Soccer"
            ></iframe>

          </div>
          <button className="start-game-cgv4 back-btn-des-cgv4" onClick={() => handleBack()}>
            <p className="mb-0 me-2 ">back</p>
            <img className="" width={40} src={assets.homev4.white_with_blue_arrow}></img>
          </button>
        </div>

        <div className="pt-5">
          <p className="cour-description-text-v4">DESCRIPTION</p>
          <p className="cour-des-text-v4">"{selectedDescriptionDetails?.name}" is an exciting game designed for kids, combining fun with learning. This interactive game challenges players to find hidden robots within a grid of squares. Each robot has its unique appearance, adding to the excitement and variety of the gameplay.</p>
        </div>

        <div className="cour-features-div-v4 mt-5">
          {currentSection === 'features' && (
            <div>
              <p className="cour-features-text-v4">Features</p>
              <p className="cour-features-des-v4">
                Grid Exploration - Navigate through a grid of squares, clicking to reveal hidden robots.
                Unique Robots - Each robot has a distinct appearance or symbol, making every discovery a new adventure.
                Objective - The goal is to uncover all hidden robots by clicking on the squares and identifying them.
              </p>
            </div>
          )}

          {currentSection === 'howToPlay' && (
            <div>
              <p className="cour-features-text-v4">How to Play</p>
              <p className="cour-features-des-v4">
                Start the Game - Click on the squares of the grid to reveal what's hidden underneath.
                Find the Robots - Identify and remember where each robot is located as you uncover them.
                Winning - Successfully find all the robots hidden within the grid to complete the game.
              </p>
            </div>
          )}

          {currentSection === 'test' && (
            <div>
              <p className="cour-features-text-v4"></p>
              <p className="cour-features-des-v4">
                Cognitive Skills - Enhance visual perception, concentration, and memory skills.
                Pattern Recognition - Develop pattern recognition abilities as players locate robots.
                Fun Learning - Provides an enjoyable environment for learning through play.
                Engaging Gameplay - Combines exploration, puzzle-solving, and visual recognition in a fun and interactive way.
              </p>
            </div>
          )}
        </div>
      </Container>

    </div>
  );
}
