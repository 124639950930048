import http from "../router/http-common";
import authHeader from "./auth/auth-header";
import api_path from "../config/api";

const register = data => {
    return http.post(api_path.parent_registration, data);
};

const GetMyLidsByParentsId = data => {    
    return http.get(api_path.p_get_mykids_by_parentid + '/'+ data, { headers: authHeader() }).then((response) => {
      return response.data;
      });
};

const addKid = data => {
    return http.post(api_path.p_addkid, data, { headers: authHeader() }).then((response) => {
        return response.data;
      });
};

const addDemoSessionKid = data => {
    return http.post(api_path.p_addDemoSessionKid, data, { headers: authHeader() }).then((response) => {
        return response.data;
      });
};

const updateKid = (data,id) => {
    return http.put(api_path.p_updatekid+'/'+id, data, { headers: authHeader() }).then((response) => {
        return response.data;
      });
};

const getKidsByKidID = (id) => {
    return http.get(api_path.p_getkidbykidid+'/'+id, { headers: authHeader() }).then((response) => {
        return response.data;
      });
};

const deleteKid = (kidid,deleteKidData) => {   
    return http.put(api_path.p_deletekid+ '/'+kidid,deleteKidData,{ headers: authHeader() }).then((response) => {
        return response.data;
      });
};

const getCourseByParentId = (id) => {
    return http.get(api_path.p_get_course_by_parent+'/'+id, { headers: authHeader() }).then((response) => {
        return response.data;
      });
};

const getkidsCourseDetails = (kidid,parentid) => {
    return http.get(api_path.p_get_allcourse_by_kid+'?'+'learnerid='+kidid+'&parentid='+parentid, { headers: authHeader() }).then((response) => {
        return response.data;
      });
};

const ParentService = {
    register,
    GetMyLidsByParentsId,
    addKid,
    addDemoSessionKid,
    updateKid,
    getKidsByKidID,
    deleteKid,
    getCourseByParentId,
    getkidsCourseDetails
};
export default ParentService;